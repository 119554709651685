/* .promotion-section ul.nav.nav-tabs {
    justify-content: space-around;
    z-index: 1;
    border-bottom: 0px;
    height: 30px;
    padding: 4px 0 0 0;
    margin-bottom: 15px;
}

.nav-link{ padding: 0; color:  #708DBB;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: transparent;
    border-color: transparent;
    color: #fff;
}
.nav-link:focus, .nav-link:hover {
    color: #708DBB;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    isolation: isolate;
    border-color: transparent;
}

.promotion-section {
    position: relative;
}

.promotion-section .bg--tab--blue {
    position: absolute;
    width: 100%;
    top: 0;
}

@media screen and (max-width: 789px){
    .promotion-section ul.nav.nav-tabs{
        height: 15px;
        padding: 1px 0 0 0;
    }
}

@media screen and (max-width: 470px) {
    .promotion-section .bg--tab--blue {
        height: 18px;
    }
}

@media screen and (max-width: 315px){
    .mb--right img {
        width: 38px;
        margin-top: 4px;
    }
} */

/* App.css */

/* .App {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
  }
  
  h1 {
    font-size: 2rem;
    margin-bottom: 20px;
  } */
  
  .promotion .swiper-container {
    width: 80%;
    max-width: 900px;
  }
  
  .promotion .swiper-slide img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  .promotion .swiper-wrapper {
    width: 430px !important;
}
.promotion .swiper {
    width: 750px !important;
}
.promotion .swiper-slide.swiper-slide-active {
    width: 110%;
    filter: blur(0);
    border-radius: 24px;
    height: auto;
    z-index: 2;
    margin: -15px auto 0;
}

.promotion .swiper-slide img {
    margin-top: 35px;
}
.promotion .swiper-slide {
    filter: blur(3px);
    z-index: 1;
  }
  /* 
.swiper-slide img {
    width: 80%;
}
.swiper-slide.swiper-slide-active {
    width: 120%;
} */

.promotion .swiper-pagination {
    position: absolute;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 10px !important;
}

@media screen and (max-width: 767px){
    
    .promotion .swiper-wrapper {
        width: 100% !important;
    }
    .promotion .swiper {
        width: 100% !important;
    }
    .promotion {
        padding: 0 4rem;
    }
    
    .promotion .swiper-slide.swiper-slide-active {
        width: 100%;
        z-index: 2;
        margin: auto;
    }
}
@media screen and (max-width: 555px) {
    .promotion {
        padding: 0 1rem;
    }

}