@font-face {
	font-family: "IBMPlexSansThai-Regular";
	src: url("../Fonts/IBMPlexSansThai/IBMPlexSansThai-Regular.woff") format("woff"),
		url("../Fonts/IBMPlexSansThai/IBMPlexSansThai-Regular.ttf") format("truetype");
}

*{
    color: #fff;
    font-family: 'IBMPlexSansThai-Regular';
}

.text-red{
    color: #FF0000;
}

.header {
    position: relative;
    width: 100%;
    background-image: url("../Images/new-theme/header/bg-header.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 80px;
    display: flex;
    justify-content: space-between;
    padding: 12px;
    z-index: 2;

    .logo{
        max-width: 230px;
        img{
            width: 100%;
            height: auto;
        }
    }

    .center{
        width: calc(100% - 630px);
        max-width: 500px;
        text-align: center;
        display: flex;
        justify-content: space-around;
        padding: 14px;
        a{
            text-decoration: none;
            color: #fff;
            font-size: 20px;
        }
        a:hover{
            color: red;
        }
    }

    .right{
        a.btn{
            width: 200px;
            height: auto;
            img{
                width: 100%;
            }
        }
    }
}

body{
    background-color: #05162F !important;
}
.pc{
    display: block;
}
.mb{
    display: none;
}

.home{
    background-color: #05162F;
    max-width: 1240px;
    margin: auto;
}
.section-1{
    position: relative;
    width: 100%;
    height: 100vh;
    background-attachment: fixed;

    .btn-logo-section{
        display: none;
    }

    .text-city-bg{
        background-image: url('../Images/new-theme/body/section-1-text.webp');
        background-size: cover;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
    }
    .city-bg{
        background-image: url('../Images/new-theme/body/section-1-city.webp');
        background-size: cover;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        position: absolute;
        bottom: -80px;
        left: 0;
        // background-attachment: fixed;
    }
}

.section-2{
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;

    .section-2 .text-left {
        z-index: 2;
    }
    .city-white{
        width: 100%;
        position: absolute;
        bottom: 0;
        z-index: 2;
    }

    .wheel-section{
        position: absolute;
        right: -20rem;
        bottom: 0;
    }
    .wheel{
        max-width: 600px;
        position: relative;
        right: 0;
        bottom: 75px;
        img{
            width: 100%;
            animation: spin 25s infinite linear;
            position: relative;
            z-index: 2;
        }
    }
    img.wheels-base {
        position: absolute;
        bottom: 0;
        width: 195px;
        left: 50%;
        transform: translate(-50%, 0);
    }
    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .text-left{
        width: 45%;
        left: 5rem;
        position: absolute;
        top: 36%;
        transform: translate(0, -50%);
        z-index: 3;

        p{
            font-size: 16px;
            text-shadow: 4px 4px 5px rgb(51 51 51);
            position: relative;
            top: 8rem;
        }
        h2{
            font-size: 24px;
            color: #5FA0FF;
            text-shadow: 4px 4px 5px rgb(51 51 51);
            position: relative;
            top: 5rem;
        }
        h1{
            font-size: 72px;
            text-shadow: 4px 4px 5px rgb(51 51 51);
            position: relative;
            top: 7rem;
        }
    }
}

.section-3{
    position: relative;
    width: 100%;
    height: calc(100vh + 175px);
    background-color: #fff;
    padding-top: 30px;
    background-image: url('../Images/new-theme/body/section-3-balloon.webp');
    background-size: contain;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;

    p{
        font-size: 16px;
        color: #05162F;
        max-width: 650px;
        margin: auto;
    }
    h4{
        font-size: 24px;
        color: #05162F;
    }
    h3{ font-size: 72px; color: #05162F;}

    .balloon{
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
    }

    .detail {
        position: relative;
        width: 90%;
        top: 50px;
        left: 50%;
        transform: translate(-50%, 0);
        color: #05162F;
        text-align: center;
    }

    .indicator {
        width: 880px;
        display: flex;
        justify-content: space-between;
        margin: 1rem auto 0;
        height: 70px;
        position: relative;
    }
    .btn-prev, .btn-next{
        width: 278px;
        height: auto;
    }

}

.promotion{
    z-index: 1;
    position: relative;
    top: 100px;
    width: 100%;
    text-align: center;
    overflow: hidden;
}
.promotion .img-slide .promotion-img{
    width: 885px;
}

.section-4{
    position: relative;
    width: 100%;
    height: auto;


    img.bg-section-4-top {
        width: 100%;
    }

    .top-logo{
        width: 100%;
        text-align: center;
        margin-top: -8rem;
        z-index: 1;
        position: relative;
        img{
            width: 400px;
        }
    }
    // img.logo {
    //     position: absolute;
    //     left: 50%;
    //     top: 0;
    //     transform: translate(-50%, 0);
    //     width: 350px;
    //     z-index: 2;
    // }

    .box-bg-games-bg{
        background-image: url('../Images/new-theme/body/section-4-bg.webp');
        background-size: cover;
        width: 100%;
        height: 100vh;
        background-repeat: no-repeat;
        position: relative;
        bottom: 0;
        left: 0;
        background-attachment: fixed;
    }

    .bg-games{
        width: 100%;
    }

    .detail {
        position: absolute;
        top: 1rem;
        width: 880px;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;

        p{
            font-size: 16px;
            max-width: 650px;
            margin: auto;
        }
        h4{
            font-size: 24px;
            color: #C2DBFF;
        }
        h3{ font-size: 72px;}
    }

    .game-section{
        position: absolute;
        bottom: 6rem;
        width: 100%;
        left: 50%;
        transform: translate(-50%, 0);

        .games {
            text-align: center;
            overflow: hidden;
            img {
                width: 140px;
                padding: 2px 8px;
            }
        }
        .bg-games-round-border{
            width: 100%;
            position: absolute;
            top: 0;
        }
    }
    .games-1, .games-3{
        animation: runningToLeft 35s infinite linear;
        display: flex;
        justify-content: center;
    }
    @keyframes runningToLeft {
        0% {
            transform: translateX(100%);
        }
        100% {
            transform: translateX(-100%);
        }
    }

    .games-2{
        animation: runningToRight 35s infinite linear;
        display: flex;
        justify-content: center;
    }
    @keyframes runningToRight {
        0% {
            transform: translateX(-100%);
        }
        100% {
            transform: translateX(100%);
        }
    }

}



.game-section{
    position: absolute;
    // bottom: 6rem;
    width: 100%;
    left: 50%;
    transform: translate(-50%, 0);

    .games {
        text-align: center;
        overflow: hidden;
        img {
            width: 140px;
            padding: 2px 8px;
        }
    }
    .bg-games-round-border{
        width: 100%;
        position: absolute;
        top: 0;
    }
}
.games-1, .games-3{
    animation: runningToLeft 35s infinite linear;
    display: flex;
    justify-content: center;
}
@keyframes runningToLeft {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.games-2{
    animation: runningToRight 35s infinite linear;
    display: flex;
    justify-content: center;
}
@keyframes runningToRight {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.section-4-1{
    width: 100%;
    height: auto;
    position: relative;
    margin-top: -265px;

    .bg-section-4-top-s{
        width: 100%;
        height: auto;
    }


    .cloud-section{
        position: absolute;
        width: 100%;
        height: 400px;
        display: block;
        z-index: 3;
        top: 22%;
        overflow: hidden;
    }
    .bg-section-4-cloud{
        background-image: url('../Images/new-theme/body/section-4-cloud.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        width: 386px;
        height: 273px;
        position: absolute;
        top: 50%;
        left: -350px;
        transform: translate(0, -50%);
    }

    .bg-section-4-moon{
        background-image: url('../Images/new-theme/body/section-4-moon.webp');
        background-size: cover;
        background-repeat: no-repeat;
        background-attachment: fixed;
        width: 386px;
        height: 247px;
        position: absolute;
        top: 50%;
        right: -350px;
        transform: translate(0, -50%);
    }

    .box-bg-games-bg {
        position: absolute;
        top: 34%;
        z-index: 4;
    }

    .box-bg-games-bg .detail {
        width: calc(100% - 200px);
        margin: auto;
        text-align: center;

        h4{font-size: 24px; text-shadow: 4px 4px 5px rgb(51 51 51);}
        h3{ font-size: 72px; font-weight: 800; text-shadow: 4px 4px 5px rgb(51 51 51); margin: 1rem auto;}
        p{font-size: 16px; text-shadow: 4px 4px 5px rgb(51 51 51); margin: 1rem auto;}
    }
}



.section-5{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 8px;


    img.bg-flag-bottom {
        width: 100%;
        position: absolute;
        top: 0;
    }

    .steps{
        display: flex;
        justify-content: space-around;
        margin-top: 4rem;
        overflow: hidden;

    }
    .step1 {
        width: 55%;
        left: 0;
        position: relative;
    }
    .step2 {
        width: 40%;
        right: 0;
        position: relative;
    }

    .detail {
        max-width: 880px;
        text-align: center;
        padding: 10rem 0 0;
        margin: auto;
        z-index: 3;
        position: relative;

        p{
            font-size: 16px;
            max-width: 650px;
            margin: auto;
        }
        h4{
            font-size: 24px;
            color: #C2DBFF;
        }
        h3{ font-size: 72px;}
    }
}

.section-6{
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 10rem;
    overflow: hidden;

    .title-section6{
        text-align: center;
        margin: 45px auto 65px;
    }
    .top{
        display: flex;
        justify-content:space-around;
        animation: runningToLeft 35s infinite linear;
        margin: 0rem auto 1rem;
    }
    .bottom{
        display: flex;
        justify-content:space-around;
        animation: runningToRight 35s infinite linear;
    }
    .brand{
        width: 280px;
        filter: grayscale(1);
    }
}

.section-7{
    position: relative;
    width: 100%;
    height: auto;
    margin-top: 8px;
    background-color: #fff;
    padding-bottom: 5rem;

    
    background-image: url('../Images/new-theme/body/section-7-bg.webp');
    background-size: 100% 320px;
    background-repeat: no-repeat;
    background-attachment: unset;
    background-position: top;

    .detail {
        max-width: 880px;
        text-align: center;
        z-index: 1;
        position: relative;
        margin: auto;

        h4{
            font-size: 24px;
            color: #C2DBFF;
        }
        h3{ font-size: 72px;}
    }

    .bg-haft-circle{
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    .article-section{
        z-index: 1;
        position: relative;
        margin-top: 10rem;

        a{
            color: red;
            text-decoration: none;
            font-size: 16px;
        }
        h4{
            color: #05162F;
            margin-left: 25px;
            margin-bottom: 20px;
        }
        .articles{
            display: flex;
            justify-content: space-around;
        }
        img.article-img {
            width: 100%;
            border-radius: 8px;
        }
        .article{
            max-height: 500px;
            height: 100%;
            // width: 370px;
            border-radius: 16px;
            border: 1px solid #E9E9E9;
            box-shadow: 10px 7px 14px #E9E9E9;
            padding: 16px;

            strong, span, .date, .detail-article, .badge{
                color: #05162F;
            }
            .detail-article h5, .detail-article p{
                color: #05162F;
            }

            .date{
                font-size: 14px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #333;
                height: 32px;
                margin: 15px 0;
            }
            .badge {
                font-size: 14px;
                background-color: #E9E9E9;
                padding: 9px 10px 0;
            }
        }
    }


    .btn-section .btn.latest {
        width: 134px;
        color: #E6F0FF !important;
        background-color: #2F83FF;
        margin: 3px;
    }
    .btn-section .btn {
        width: 134px;
        color: #2F83FF !important;
        background-color: #E6F0FF;
        margin: 3px;
    }

    .detail-article h5 {
        font-weight: 700;
        font-size: 20px;
        margin: 15px auto 10px;
    }
}

.section-8{
    position: relative;
    width: 100%;
    height: auto;
    padding: 7rem 2rem;
    display: flex;
    justify-content: space-between;

    .left{
        img{
            width: 346px;
            margin: 16px 0;
        }
        h3{
            font-size: 40px;
        }
    }
    .right{
        max-width: 387px;
        .btn img{
            width: 100%;
        }
    }
}

.section-9{
    position: relative;
    width: 100%;
    height: auto;
    background: linear-gradient(#05152F 0%, #104395 83%);
    overflow: hidden;

    .bottom-logo-box{
        max-width: 1179px;
        margin: auto;
    }
    img{
        width: 100%;
    }

    .bottom-menu{
        margin-left: 4rem;
        margin-top: -2rem;
    }
    .bottom-menu a {
        width: 120px;
        display: inline-block;
        color: #fff;
        text-decoration: none;
        font-size: 20px;
    }
    img.bottom-flag {
        margin-top: -5rem;
    }

    img.btn-to-top {
        width: 72px;
        position: absolute;
        top: 40%;
        left: 90%;
    }

    .bottom-logo-box{
        animation: runningToLeft 15s infinite linear;
        display: flex;
        justify-content: center;
    }
}

@media screen and (max-width: 1290px) {
    .game-section .games img {
        width: 120px;
        padding: 2px 8px;
    }
}

@media screen and (max-width: 1117px) {
    .section-3 {
        height: calc(100vh + 250px);
    }
}


@media screen and (max-width: 1099px) {
    .section-2 .wheel {
        max-width: 450px;
        bottom: 180px;
    }

    .promotion .img-slide .promotion-img,
    .section-3 .indicator {
        width: 655px;
    }

    .section-4 .top-logo img {
        width: 275px;
    }
    .section-4 .detail h3 {
        font-size: 35px;
    }

    .section-6 {
        margin-bottom: 5rem;
    }


    // .section-7 .article-section .article{
    //     width: 30%;
    // }

    .section-9 .bottom-menu {
        margin-left: 1rem;
    }
}
@media screen and (max-width: 1028px) {
    .header .center a {
        font-size: 14px;
    }
    .game-section .games img {
        width: 100px;
    }
    .games-1 {
        margin-top: 33px;
    }

    .section-6 .brand {
        width: 140px;
    }
    .section-5 .detail {
        padding: 6rem 0 0;
    }

    .section-4-1 .box-bg-games-bg .detail h3 {
        font-size: 52px;
    }
}
@media screen and (max-width: 922px) {
    .section-2 .text-left h1 {
        font-size: 52px;
    }

    .section-3 {
        height: calc(100vh + 150px);
    }
    .section-3 h3 {
        font-size: 52px;
    }
    .promotion .img-slide .promotion-img,
    .section-3 .indicator {
        width: 430px;
    }
    .section-3 .btn-prev, .section-3 .btn-next {
        width: 178px;
    }


    .game-section .games img {
        width: 88px;
    }
    .section-4 .detail {
        width: 100%;
    }
    .game-section {
        top: 300px;
    }


    .section-5 .detail h3 {
        font-size: 52px;
    }


    .section-7 .detail h3 {
        font-size: 52px;
    }
    .section-7{
        background-size: 100% 215px;
        .btn-section .btn.latest {
            width: 94px;
        }
        .btn-section .btn {
            width: 94px;
        }
    }



    .section-8 .left h3 {
        font-size: 26px;
    }
    .section-9 .bottom-menu a {
        width: 95px;
        font-size: 16px;
    }
    .section-8 .right {
        max-width: 300px;
        margin: 16px 0;
    }
    .section-8 {
        padding: 5rem 2rem;
    }
    .section-7 .article-section {
        margin-top: 5rem;
    }



    .section-1 .city-bg{
        background-image: url('../Images/new-theme/body/mb/mb-section-1-city.webp');
    }
    
}
@media screen and (max-width: 767px) {

    .pc{
        display: none;
    }
    .mb{
        display: block;
    }

    .header {
        justify-content: center;
        .center{
            display: none;
        }
    }


    .section-1 .btn-logo-section {
        display: block;
        position: absolute;
        bottom: 0;
        z-index: 0;
        width: 100%;
        margin: auto;
        left: 50%;
        transform: translate(-50%, 0);
        text-align: center;
        background-color: #05162F;
        height: 160px;
        padding: .5rem 0 0 0;
    }
    .section-1 .city{
        width: 100%;
        margin-bottom: -1px;
    }
    .section-1 .text-city-bg {
        background-image: url('../Images/new-theme/body/mb/mb-section-1-text.webp');
        background-size: cover;
    }
    .section-1 .city-bg {
        background-size: contain;
        display: block;
        width: 100%;
        background-position: left 90%;
        // min-width: 382px;
    }

    .section-2 {
        height: auto;
    }
    .section-2 .text-left {
        width: 100%;
        left: 0;
        position: relative;
        top: 1.5rem;
        transform: unset;
        padding: 2rem;
    }
    .section-2 .mb .btn-section {
        z-index: 1;
        position: fixed;
        padding: 1rem;
        bottom: 0;
        display: flex;
        justify-content: space-around;
        z-index: 20;
        img{
            width: 100%;
        }
    }
    .section-2 .wheel {
        max-width: 90%;
        position: relative;
        margin: auto;
        bottom: 0;
    }
    .section-2 .city-white {
        width: 100%;
        position: relative;
    }
    .section-2 .wheel-section {
        // position: relative;
        right: -250px;
        bottom: -80px;
    }
    .section-2 img.wheels-base {
        bottom: -60px;
        width: 205px;
        // display: none;
    }


    .section-3 {
        height: 1280px;
        background-image: url('../Images/new-theme/body/mb/mb-section-3-balloon.webp');
        background-size: cover;
        background-position: top center;
    }
    .section-3 .detail {
        text-align: right;
    }
    .section-3 h4 {
        font-size: 18px;
    }
    .section-3 h3 {
        width: 280px;
        margin-left: auto;
        font-weight: 700;
    }
    .section-3 p {
        max-width: 400px;
        margin-right: 0;
    }


    .section-4 .box-bg-games .mb {
        margin-top: 3px;
        background: #05162F;
        padding-top: 4rem;
    }
    .section-4 .top-logo img {
        width: 190px;
    }
    .section-4 img.bg-section-4-top {
        z-index: 1;
        position: relative;
    }
    .section-4 .detail {
        width: 100%;
        left: 0;
        transform: unset;
        text-align: left;
        padding: 0 2rem;
        top: 10%;
    }
    .section-4 .detail h4 {
        font-size: 16px;
        width: 250px;
    }
    .section-4 .detail h3 {
        font-weight: 700;
        width: 270px;
    }
    .section-4 .detail p {
        margin-left: 0;
    }

    // .game-section .games img {
    //     width: 18%;
    // }
    .section-4 .box-bg-games .mb {
        padding-top: 0;
    }
    .section-4 .box-bg-games .games .mb {
        margin-top: 3px;
        padding-top: 0;
    }
    .section-4 .box-bg-games-bg {
        background-image: url('../Images/new-theme/body/mb/mb-section-4-bg.webp');
        background-size: 100% 100%;
        background-attachment: unset;
        height: 950px;
    }
    // .game-section {
    //     top: 50%;
    // }



    .bg-section-4-cloud{
        background-image: url('../Images/new-theme/body/section-4-cloud.webp');
        width: 191px;
        height: 135px;
        left: -275px;
    }

    .bg-section-4-moon{
        background-image: url('../Images/new-theme/body/section-4-moon.webp');
        width: 191px;
        height: 122px;
        right: -275px;
    }
    .section-4-1 .cloud-section {
        top: 16%;
    }
    .section-4-1 .box-bg-games-bg {
        position: absolute;
        top: 26%;
        z-index: 4;
    }
    .game-section {
        top: 490px;
    }
    .section-4-1 .box-bg-games-bg .detail {
        width: calc(100% - 100px);
        text-align: left;
    }






    .section-5 .steps {
        display: block;
    }
    .section-5 .step1, .section-5 .step2 {
        width: 100%;
        padding: 0 1rem 1rem;
    }
    .section-5 .detail {
        padding: 6rem 1rem 0;
        text-align: left;
    }
    .section-5 .detail p {
        margin-left: 0;
    }
    .step1 {
        left: -250px;
    }
    .step2 {
        right: -250px;
    }


    .section-6 .title-section6 {
        margin: 1rem auto 2rem;
    }


    .section-7 .detail {
        text-align: left;
        padding: 1rem;
    }
    .section-7 .bg-haft-circle {
        max-height: 235px;
    }
    .section-7 .btn-section button.btn {
        color: #fff;
    }

    .section-7{
        background-image: url('../Images/new-theme/body/mb/mb-section-7-bg.webp');
        background-size: 100% 27%;
        .detail h3 {
            font-size: 32px;
        }
    }


    .section-8 {
        display: none;
    }


    .section-9 img.btn-to-top {
        width: 60px;
        left: 85%;
    }
    .section-9 .bottom-logo-box {
        max-width: 328px;
        margin: 3rem auto;
    }
    .section-9 .bottom-menu{
        display: none;
    }
}

.sticky-header {
    position: fixed;
    top: -80px;
    left: 0;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    text-align: center;
    font-size: 1.5em;
    transition: top 0.3s ease-in-out;
    z-index: 1000;
}

.sticky-header.visible {
    top: 0; /* แสดงเมื่อ scroll ลง */
    padding-top: 20px;
}

@media screen and (max-width: 643px) {
    .section-4-1 .box-bg-games-bg .detail h4 {
        width: 326px;
    }
}

@media screen and (max-width: 555px) {
    .section-1 {
        overflow: hidden;
    }

    .section-3 {
        height: calc(100vh + 250px);
    }


    .section-4 .box-bg-games {
        margin-top: -1px;
    }
    .section-4 img.bg-section-4-top {
        margin: -9px 0 0;
    }

    .game-section {
        top: 515px;
    }
}

@media screen and (max-width: 500px) {
    .section-1 {
        overflow: hidden;
        height: auto;
        min-height: 450px;
    }
    .section-1 .city {
        top: 50%;
        left: 0;
        transform: translate(0, -50%);
    }


    .section-2 .text-left h2 {
        font-size: 22px;
    }
    .section-2 .text-left h1 {
        font-size: 42px;
    }

    .section-3 h3 {
        font-size: 42px;
    }


    .section-4 .detail {
        top: 6rem;
    }

    .section-5 .detail h4 {
        font-size: 18px;
    }
    .section-5 .detail h3 {
        font-size: 42px;
    }
}

@media screen and (max-width: 473px) {
    .games-1 {
        margin-top: 26px;
    }
}

@media screen and (max-width: 460px) {
    .section-1 .city-bg {
        background-position: left 80%;
    }
}

@media screen and (min-width: 440px) {
    .section-7 .detail .btn-section{
        text-align: center;
    }
}

@media screen and (max-width: 440px) {
    .section-4-1 .box-bg-games-bg .detail h3 {
        font-size: 42px;
    }
    .game-section {
        top: 500px;
    }
    .game-section .games img {
        width: 75px;
    }
}



@media screen and (max-width: 432px) {
    .section-1 .city-bg {
        background-position: left 82%;
    }
}

@media screen and (max-width: 430px) {
    .section-2 .wheel {
        bottom: 149px;
    }

    .section-3 {
        height: calc(100vh + 150px);
    }

    .section-4 .box-bg-games-bg {
        height: 700px;
    }
    .section-4 .detail {
        top: 0;
    }


    .section-4-1 .box-bg-games-bg .detail h4 {
        font-size: 19px;
        width: 237px;
    }
    .section-4-1 .box-bg-games-bg .detail h3 {
        font-size: 39px;
        margin: 1.5rem auto;
    }
    .game-section {
        top: 440px;
    }
    .section-4-1 .box-bg-games-bg {
        top: 24%;
    }
    .section-4-1 .box-bg-games-bg .detail p {
        font-size: 14px;
    }
}

@media screen and (max-width: 394px) {
    .section-1 .city-bg {
        background-position: left 83%;
    }
}

@media screen and (max-width: 390px) {
    .games-1 {
        margin-top: 15px;
    }
}

@media screen and (max-width: 382px) {
    .section-2 .text-left h1 {
        font-size: 40px;
    }

    .section-4-1 .box-bg-games-bg .detail h3 {
        font-size: 32px;
    }
}
@media screen and (max-width: 378px) {
    .section-1 .city-bg {
        background-position: left 84%;
    }
}