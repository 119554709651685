.promotion--page-section ul.nav.nav-tabs {
  justify-content: space-around;
  z-index: 1;
  border-bottom: 0px;
  height: 30px;
  padding: 4px 0 0 0;
  margin-bottom: 15px;
  top: 108px;
  background-image: url(../Images/btn-bg-darkblue.png) !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat;
}

.nav-link{ padding: 0; color:  #708DBB;}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent;
  color: #fff;
}
.nav-link:focus, .nav-link:hover {
  color: #708DBB;
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
  isolation: isolate;
  border-color: transparent;
}

.promotion--page-section .promotion-section {
  position: relative;
}

.promotion--page-section .promotion-section .bg--tab--blue {
  position: absolute;
  width: 100%;
  top: 0;
}






.promotion--page-section .container {
  position: relative;
}
.promotion--page-section .container h3 {
  display: block;
  text-align: center;
  margin: 40px 0 10px;
}

.promotion--page-section .container .bg--tab--blue {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media screen and (max-width: 550px){
  .pc {
      display: none;
  }
  .mobile {
      display: block;
  }
  .mobile .tab-content {
      width: 100%;
      margin: auto;
  }
  .promotion--page-section ul.nav.nav-tabs {
      background-image: url(../Images/btn-bg-darkblue.png) !important;
      background-size: 100% 100% !important;
      background-repeat: no-repeat;
      width: 90%;
      margin: auto;
      font-size: 16px;
  }
  .promotion--page-section .container .bg--tab--blue {
      height: 30px;
  }
  .promotion--page-section {
      margin-bottom: 100px;
  }

  .games.mb-2.col-md-4.col-6 {
      width: 100%;
      padding: 0 3px;
  }

  .img-btn-login {
      width: 100%;
      margin: 5px 0;
  }
  marquee.marquee--text {
      font-size: 14px;
  }
}