.wrapper--menu--hamburger {
  width: 100%;
  /* background-color: rgba(8, 2, 71, .7);
    background-image: none;
    background-size: cover;
    position: fixed;
    top: 85px; */
  z-index: 6;
  height: 100%;
  padding-bottom: 60px;

  /* position: fixed;
    left: 0;
    height: calc(100vh - 64px);
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    transition-property: all;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
    transition-duration: .15s;
    transition-duration: .4s; */
}
.wrapper--section--hamburger {
  width: 328px;
  margin: 20px auto;
}
.menu--hamburger.col-6 {
  position: relative;
  padding: 15px;
  background-image: url("https://fs.cdnxn.com/landingLondon/Images/box-white.png");
  background-size: 100% 100%;
  background-position: center;
  box-shadow: none;
  border-radius: 0;
  border: none;
  width: 165px;
  margin: 5px 5px;
  height: 110px;
}
.menu--hamburger.col-12 {
  position: relative;
  padding: 10px 0 5px;
  background-image: url("https://fs.cdnxn.com/landingLondon/Images/box-white-sm.png");
  background-size: 100% 100%;
  background-position: center;
  box-shadow: none;
  border-radius: 0;
  border: none;
  width: 99%;
  margin: 5px 0px;
  text-align: center;
}
.menu--hamburger.col-6 a,
.menu--hamburger.col-12 a {
  text-decoration: none;
  color: #000;
}
.menu--hamburger.col-6 a .menu--hamburger--img {
  text-align: center;
  font-size: 14px;
  position: relative;
}
.menu--hamburger.col-6 a .menu--hamburger--img img {
  width: 56px;
}
.menu--hamburger.col-6 h5 {
  position: absolute;
  width: 137px;
  bottom: 0;
  text-align: center;
  font-size: 15px;
  font-weight: 500;
}
.menu--hamburger.col-12 .menu--hamburger--btn h5 {
  font-size: 16px;
}
